import React from "react"
import Styles from "./css/installVid.module.scss"
import StylesC from "./css/chromelpez.module.scss"
import StylesF from "./css/chunkyfooter.module.scss"
import footerData from "./data/installVid.json"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"

class InstallVid extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: footerData,
      step1: 'block',
      step2: 'none'
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (typeof window != "undefined") {
      window.mapParams();
    }
    });
  }

  step2() {
    this.setState({step1: 'none'});
    this.setState({step2: 'block'});
    setTimeout(function(){
      window.triggerInstall();
    },1000);
  }

  step1() {
    this.setState({step1: 'block'});
    this.setState({step2: 'none'});
  }

  render() {
    return (
      <>
        <div id = {Styles.installVid} style={{ display: this.state.step2 }}>
          <div className = {Styles.extContainer}>
            <div>
              <div className = {Styles.extClose} onClick={() => this.step1()}>
                <img src="/img/close-exta.png" />
              </div>
              <div className = {Styles.extRedl}>
                Download Not Start? Click Here to Get EasyView
                <button onClick={() => window.triggerInstall()} className = {Styles.overlayCTA}>
                  Download
                </button>
              </div>
              <h3 className = {Styles.extH}>Thank You For Installing EasyView on Edge</h3>
              <h2>
                <strong>Next</strong> Enable EasyView on Edge Browser
              </h2>
              <div className = {Styles.installDirection}>
                <div className = {Styles.videoDirection}>
                  <video className = {Styles.vidplay} controls>
                    <source src="/img/edge_ext_install.webm" type="video/webm" />
                    <source src="/img/edge_ext_install.mp4" type="video/mp4" />
                  </video>
                </div>
                <div className = {Styles.textDirection}>
                  <h3>No time to watch video? Here is a step by step install guide.</h3>
                  <div className = {Styles.tdContainer}>
                    <div className = {Styles.tdList}>
                      <div className = {Styles.tdLabel}>Step 1</div>
                      <div className = {Styles.tdImg}>
                        <img src="/img/edge-install1.jpg" />
                      </div>
                      <div>
                        At the top corner of the browser, select Settings and more &gt;
                        Extensions
                      </div>
                    </div>
                    <div className = {Styles.tdList}>
                      <div className = {Styles.tdLabel}>Step 2</div>
                      <div className = {Styles.tdImg}>
                        <img src="/img/edge-install2a.jpg" />
                      </div>
                      <div>
                        In the Extensions popup window, select{" "}
                        <strong>Manage Extension</strong>
                      </div>
                    </div>
                    <div className = {Styles.tdList}>
                      <div className = {Styles.tdLabel}>Step 3</div>
                      <div className = {Styles.tdImg}>
                        <img src="/img/edge-install2.jpg" />
                      </div>
                      <div>
                        Select the <strong>Toggle</strong> to turn on EasyView Search
                      </div>
                    </div>
                    <div className = {Styles.tdList}>
                      <div className = {Styles.tdLabel}>Step 4</div>
                      <div className = {Styles.tdImg}>
                        <img src="/img/edge-install3.jpg" />
                      </div>
                      <div>
                        Click <strong>Keep Changes</strong> for EasyView Search New Tab
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div id = {StylesC.chromelpez4} style={{ display: this.state.step1 }}>

            <div className = {StylesC.header}><img src="/img/logo.png" alt="" /></div>
         <div className = {StylesC.main}>
           <h1 className = "hc_param">{this.state.data.headline}</h1>

           <div className = {StylesC.stepGrid}>
             <div className = {StylesC.step}>1</div>
             <div className = {StylesC.copy}>
               <h2>{this.state.data.subhead1}</h2>
               <p>{this.state.data.copy1}</p>
             </div>

             <div className = {StylesC.step}>2</div>
             <div className = {StylesC.copy}>
               <h2>{this.state.data.subhead2}</h2>
               <p>{this.state.data.copy2}</p>
             </div>
           </div>
           <div className = {StylesC.comp}>{this.state.data.compliance}</div>
            <button className = {`${StylesC.download} cl1`} data-cy="cta" onClick={() => this.step2()}>{this.state.data.button}</button>
              {this.props.children}
         </div>
          <div id = {StylesF.chunkyFooter}>
            <h3 className = {StylesF.footerHeadline}>{this.state.data.footerHeadline}</h3>
            <ul className = {StylesF.footerList}>
                  {this.state.data.footerList != undefined ? this.state.data.footerList.map((bullet, i) => {
                      return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                    }) : null}
            </ul>
            <img className = {StylesF.footerImg} src={this.state.data.footerImage} />
            <button onClick={() => this.step2()} data-cy="cta" className = {`${StylesF.footerButton} ${StylesF.bn_param} ${StylesF.cws_dl} cl1`}><span dangerouslySetInnerHTML={{ __html: this.state.data.button }}></span></button>
            {this.props.children}
          </div>
        </div>
      </>
    )
  }
}
export default InstallVid
